
	
	.custom-radio .form-check-input {
	  opacity: 0; /* 隐藏默认单选框 */
	  position: absolute;
	}
	
	.custom-radio-image {
	  display: flex;
	  align-items: center;
	  cursor: pointer;
	}
	
	.custom-radio-image img {
	  width: 24px; /* 图片宽度 */
	  height: 24px; /* 图片高度 */
	  margin-right: 8px; /* 图片与文字的间距 */
	}
	
	 /* 外层容器，让 5 个 sound-wave 水平排列 */
	    .sound-wave-container {
	      display: flex;
	
	    }
	    
	    /* 单个 sound-wave 样式 */
	    .sound-wave {
	      display: flex;
	      align-items: flex-end;
	      height: 10px;
	      margin-top: 10px;
	      margin-bottom: 20px;
	    }
	    
	    /* sound-bar 样式 */
	    .sound-bar {
	      width: 2px;
	      height: 4px;
	      background-color: #53ADFF;
	      margin-right: 2px;
	      transform: scaleY(1);
	      animation-duration: 0.5s;
	      animation-iteration-count: infinite;
	      animation-direction: alternate;
	      animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
	    }
	    
	    /* 给不同的 bar 应用不同的动画 */
	    .sound-bar:nth-child(4n) {
	      animation-name: bar-scale-xl;
	      animation-duration: 1s;
	    }
	    
	    .sound-bar:nth-child(4) {
	      animation-duration: 1.15s;
	    }
	    
	    .sound-bar:nth-child(3) {
	      animation-name: bar-scale-lg;
	      animation-duration: 0.8s;
	    }
	    
	    .sound-bar:nth-child(6) {
	      animation-name: bar-scale-md;
	      animation-duration: 0.85s;
	    }
	    
	    .sound-bar:nth-child(2),
	    .sound-bar:nth-child(5),
	    .sound-bar:nth-child(7),
	    .sound-bar:nth-child(9) {
	      animation-name: bar-scale-sm;
	      animation-duration: 0.9s;
	    }
	    
	 @keyframes bar-scale-sm {
	   0%,
	   50% {
	     transform: scaleY(1);
	   }
	   25% {
	     transform: scaleY(2); /* 调整为较小的幅度 */
	   }
	   75% {
	     transform: scaleY(1.5); /* 调整为较小的幅度 */
	   }
	 }
	 
	 @keyframes bar-scale-md {
	   0%,
	   50% {
	     transform: scaleY(1);
	   }
	   25% {
	     transform: scaleY(2.5); /* 调整为较小的幅度 */
	   }
	   75% {
	     transform: scaleY(2); /* 调整为较小的幅度 */
	   }
	 }
	 
	 @keyframes bar-scale-lg {
	   0%,
	   50% {
	     transform: scaleY(1);
	   }
	   25% {
	     transform: scaleY(3); /* 调整为较小的幅度 */
	   }
	   75% {
	     transform: scaleY(2); /* 调整为较小的幅度 */
	   }
	 }
	 
	 @keyframes bar-scale-xl {
	   0%,
	   50% {
	     transform: scaleY(1);
	   }
	   25% {
	     transform: scaleY(3.5); /* 调整为较小的幅度 */
	   }
	   75% {
	     transform: scaleY(2.5); /* 调整为较小的幅度 */
	   }
	 }